import axios from "axios";
import store from "@/store";
import router from "@/router";
import { Message } from "element-ui";
import JSONbig from 'json-bigint'
axios.defaults.timeout = 1000 * 60;
axios.defaults.withCredentials = true;
axios.defaults.headers["Content-Type"] = "application/json;charset=utf8";
// 解决number超精度问题
axios.defaults.transformResponse = function (data) {
  try {
    // 如果转换成功则返回转换的数据结果
    return JSONbig.parse(data)
  } catch (err) {
    // 如果转换失败，则包装为统一数据格式并返回
    return {
      data
    }
  }
}
// axios 请求 拦截器
axios.interceptors.request.use(
  config => {
    config.headers["token"] = store.getters.token;
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);
// axios 响应 拦截器
axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    const { errors } = error.response.data;
    Message.close();
    Message.error(errors[0].msg);
    if (errors[0].code === -20000) {
      store.commit("user/SET_TOKEN", null);
      store.commit("user/SET_USERINFO", null);
      router.push("/login");
    }
    return Promise.reject(error);
  }
);
const request = (url = "", data = {}, method = "GET") => {
  return new Promise(function (resolve, reject) {
    let promise;
    method = method.toLocaleUpperCase();
    switch (method) {
      case "GET": {
        promise = axios.get(url, { params: data });
        break;
      }
      case "POST": {
        promise = axios.post(url, data);
        break;
      }
      case "PUT": {
        promise = axios.put(url, data);
        break;
      }
      case "DELETE": {
        promise = axios.delete(url);
        break;
      }
    }
    promise
      .then(response => {
        const { code, errors } = response.data;
        switch (code) {
          case 0:
            resolve(response.data);
            break;
          default:
            Message.close();
            Message.error(errors[0].msg);
            resolve(response.data);
            break;
        }
      })
      .catch(error => {
        reject(error);
      });
  });
};
export default request;
