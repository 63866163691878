// export const XHR_URL = "/proxyUrl"
//export const XHR_URL = "http://franchisee.aivmcloud.com"  
// export const XHR_URL = "http://par.foodieb.com"
 export const XHR_URL = "https://anrui-fr.goalcan.work"
 
export const PAGE_SIZE = 20
export const APP_TYPE = "franchisee"
export const APP_TYPE_CODE = 2


